/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ngx-toastr/toastr.css";

body {
  background-color: #f5f8fb;
  //font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

p {
  font-family: 'Poppins';
}

/* Custom scollbar*/
.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 5px;
  background-color: #EBEBEB;
  border: 1px solid #FFFFFF;
}

.frame::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #EBEBEB;
  border: 1px solid #FFFFFF;
}

.frame::-webkit-scrollbar-thumb {
  background-color: #f5f8fb;
  border: 1px solid #f5f8fb;
  border-radius: 20px;
  height: 7px;
}

/*=======*/
/*Custom Classes*/


.account-body .auth-header-box {
  background-color: #ED4C9A;
}

body.dark-sidenav .left-sidenav {
  background-color: #ED4C9A;
  min-width: 204px;
}

.header-logo {
  background-image: url('assets/images/petsRwise-logo.svg');
  width: 50px;
  height: 50px;
  display: block;
  background-repeat: no-repeat;
}

.left-sidenav-menu {
  padding: 0px;
}

.dashboard-logo {
  height: 28px;
  margin-top: 24px;
  margin-left: 33px;
}

.hide {
  display: none;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  background-color: #0096FF;
  border-color: #0096FF;
}

.text-muted {
  color: #5D5D5D !important;
}

.text-primary {
  color: #F34D9F !important;
}

.cursor-hand {
  cursor: pointer;
}

app-root {
  width: 100%;
}

.emailConfrm {
  padding-top: 80px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 60px !important;
}

.invalid-feedback {
  display: block;
}

/* dashboard */
body.dark-sidenav .left-sidenav-menu li ul li > a {
  color: #fff;
}

.clrwhite {
  color: #fff;
}

.ti-control-record {
  display: none;
}

.page-wrapper .page-content {
  padding: 0px 0px 60px 0px;
}

.navbar-custom header {
  display: inline-block;
  width: 600px;
  height: 70px;
  font-size: 30px;
  color: #5F5F5F;
  padding: 20px 29px;
}

/* New style */
td.last-seen {
  position: relative;
}

td.last-seen span.lSeen {
  position: absolute;
  top: 0;
  width: 300px;
  color: #5F5F5F;
  font-size: 11px;
  margin: 10px 0 20px 0;
}

.last-seen input[type="radio"] {
  margin: 5px 0;
}

.table {
  border: 1px solid #EEEEEE;
}

.table thead th {
  min-width: 150px
}

//.table td{
//  padding: 40px 10px 10px 10px !important;
//}

.left-sidenav-menu li > a {
  padding: 10px 0;
  width: 180px;
  margin-left: 24px;
}

.left-sidenav-menu li > a .menu-icon {
  width: 4px;
}

.left-sidenav-menu {
  padding-right: 0;
}

.left-sidenav-menu a.nav-link:hover {
  background-color: #f171af;
  border-radius: 20px 0 0 20px;
}

.left-sidenav-menu a.nav-link.active {
  background-color: #f171af;
  border-radius: 20px 0 0 20px;
}


/*Table's related common classes*/
.user-status {
  display: inline;
  position: relative;
  margin-left: 18px;
}

.user-status img.user-icon {
  width: 32px;
  height: 32px;
}

.user-status img.user-icon.not-verified {
  border: 1px solid red;
  border-radius: 45px;
}

.user-status .is-verified {
  position: absolute;
  left: 20px;
  top: 14px;
}

.user-status .is-verified img.user-tick {
  width: 15px;
  height: 15px;
}

.country-icon-wrapper {
  margin-right: 5px;
}

.flag-icon {
  width: 30px;
  height: 30px;
}

/*tooltip class*/
.as-tooltip {
  position: relative;
  display: inline-block;
}

.as-tooltip .tooltiptext {
  visibility: hidden;
  width: 70px;
  background-color: #FA7589;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 190%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}

.as-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.scroll {
  margin-top: 17px;
}

/*action panel class for buttons*/
.action-panel {
  width: 100%;
  height: 74px;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
  position: fixed;
  bottom: 0px;
}

.action-panel .as-btn {
  display: inline-block;
  margin-right: 20px;
  min-width: 150px;
  height: 42px;
  font-size: 16px;
  border-radius: 25px;
  padding: 8px;
  border: none;
  color: #fff;
  background: #000;
}

.action-panel.show {
  display: block;
}

.action-panel.hide {
  display: none;
}

.close-ac-panel {
  height: 20px;
  cursor: pointer;
  width: 20px;
  font-size: 20px;
  color: #a3a3a3;
}

/*button color classes*/
.btn-cGreen {
  background: #77CEA9 !important;
}

.btn-cPink {
  background: #ED4D9B !important;
}

.btn-cGrey {
  background: #717171 !important;
}

.btn-cBlue {
  background: #0096FF !important;
}

/*dashboard style*/
.user-profile-icon {
  width: 40px !important;
  height: 40px !important;
}

.list-group {
  list-style: none;
}

#search::-webkit-search-cancel-button {
  position: relative !important;
  right: 20px !important;
  height: 10px;
  width: 10px;
  display: inline-block;
  background: blue;
  cursor: pointer;
}

.left-sidenav .menu-content {
  padding-bottom: 100px;
  overflow: auto;
}

.left-sidenav .menu-content::-webkit-scrollbar {
    width: 5px;
    background: #b3b3b3;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.left-sidenav .menu-content::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.left-sidenav .menu-content::-webkit-scrollbar-thumb:active {
  background-color: #0096FF;
}
.left-sidenav .menu-content::-webkit-scrollbar-thumb {
  background-color: #0096FF;

}

body::-webkit-scrollbar {
  width: 5px;
  background: #b3b3b3;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
body::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
body::-webkit-scrollbar-thumb:active {
  background-color: #0096FF;
}
body::-webkit-scrollbar-thumb {
  background-color: #0096FF;

}

.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #b3b3b3;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.table-responsive::-webkit-scrollbar-thumb:active {
  background-color: #0096FF;
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #0096FF;
}

tr:hover {
  background-color: #eaf0f7;
  cursor: pointer;
}

.deactivate {
  color: #aba9a9;
}

.verified {
  color: green;
}

.pointer-element {
  cursor: pointer;
}

.dropify-wrapper {
  height: 120px !important;
  border: none !important;
}


